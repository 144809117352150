import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

import Account from '@/views/account/routes';

const routes : Array<RouteConfig> = [
	{
		path      : '/',
		component : () => import(/* webpackChunkName: "home" */ '../layouts/Default.vue'),
		children  : [
			{
				path      : '',
				name      : 'lists',
				meta      : {title : 'Skills - MyApp'},
				component : () => import(/* webpackChunkName: "lists" */ '../views/Lists.vue')
			},
			{
				path      : '/mylists',
				name      : 'mylists',
				component : () => import(/* webpackChunkName: "items" */ '../views/MyLists.vue'),
				props     : true
			},
			{
				path      : '/list/:listId',
				name      : 'items',
				component : () => import(/* webpackChunkName: "items" */ '../views/Items.vue'),
				props     : true
			},
			{
				path      : '/list/:listId/play',
				name      : 'play',
				component : () => import(/* webpackChunkName: "items" */ '../views/Play.vue'),
				props     : true
			},
			{
				path      : '/daily',
				name      : 'daily',
				component : () => import(/* webpackChunkName: "items" */ '../views/Daily.vue'),
				props     : true
			},
			{
				path      : '/profile',
				name      : 'profile',
				component : () => import(/* webpackChunkName: "items" */ '../views/Profile.vue'),
				props     : true
			},
			{
				path      : '/search',
				name      : 'search',
				component : () => import(/* webpackChunkName: "items" */ '../views/Search.vue'),
				props     : true
			},


			// {
			//     path: '/users',
			//     name: 'users',
			//     component: () => import(/* webpackChunkName: "Users" */ '../views/Users.vue')
			// },
			{
				path      : '/admin',
				name      : 'admin',
				component : () => import(/* webpackChunkName: "admin-layout" */ '../views/Admin/Layout.vue'),
				children  : [
					{
						path      : 'lists',
						name      : 'admin.lists',
						component : () => import(/* webpackChunkName: "admin-lists" */ '../views/Admin/Index.vue')
					},
					{
						path      : 'words/:listId?',
						name      : 'admin.words',
						component : () => import(/* webpackChunkName: "admin-words" */ '../views/Admin/Words.vue')
					},
					{
						path      : 'users',
						name      : 'admin.users',
						component : () => import(/* webpackChunkName: "admin-users" */ '../views/Admin/Users.vue')
					},
					{
						path      : 'purchases',
						name      : 'admin.purchases',
						component : () => import(/* webpackChunkName: "admin-users" */ '../views/Admin/Purchases.vue')
					},
					{
						path      : 'supermemo',
						name      : 'admin.supermemo',
						component : () => import(/* webpackChunkName: "admin-users" */ '../views/Admin/Supermemo.vue')
					}
				]
			}
		],
		meta      : {
			requiresAuth : true
		}
	},
	Account
];

const router = new VueRouter({
	routes
});

export default router;
