






















import { Vue, Component } from 'vue-property-decorator';
import { Toast, Tooltip } from 'bootstrap';

@Component({})
export default class App extends Vue {
	private registration : any = null;
	private isRefresh : boolean = false;
	private refreshing : boolean = false;
	public location : any = {};

	mounted()
	{
		(window as any).popStateDetected = false;
		(window as any).addEventListener('popstate', () => {
			(window as any).popStateDetected = true;
		});
	}

	created()
	{
		// this.toggleToast('show')
		navigator.serviceWorker.addEventListener(
			'controllerchange', () => {
				if (this.refreshing) return;
				this.refreshing = true;
				window.location.reload();
			}
		);
		document.addEventListener(
			'serviceWorkerUpdateEvent', this.appUpdateUI, {once : true}
		);
	}

	appUpdateUI(e : any)
	{
		this.registration = e.detail;
		this.isRefresh = true;
		this.toggleToast('show');
	}

	updateLocation()
	{
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(pos => {
				this.location = {
					lat  : pos.coords.latitude,
					long : pos.coords.longitude
				};
			}, err => {
				this.location = err.message;
			});
		}
	}

	update()
	{
		this.toggleToast('hide');
		this.isRefresh = false;
		if (this.registration || this.registration.waiting) {
			this.registration.waiting.postMessage({type : 'SKIP_WAITING'});
		}
	}

	toggleToast(type : string)
	{
		const toastEl = document.getElementById('updateDone');
		const toast = Toast.getOrCreateInstance(toastEl);
		if (type === 'show') {
			toast.show();
		} else {
			toast.hide();
		}
	}

}

