import feathersClient, {
	makeServicePlugin,
	BaseModel
} from '../feathers-client.js'

class WordOfTheDay extends BaseModel {
	// eslint-disable-next-line no-useless-constructor
	constructor(data, options) {
		super(data, options)
	}

	// Required for $FeathersVuex plugin to work after production transpile.
	static modelName = 'WordOfTheDay'

	// Define default properties here
	static instanceDefaults() {
		return {
			name: '',
			userId: '',
			_v: '',
			_id: ''
		}
	}
}

const servicePath = 'wordoftheday'
const servicePlugin = makeServicePlugin({
	Model: WordOfTheDay,
	service: feathersClient.service(servicePath),
	servicePath,
	skipRequestIfExists: true,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
	before: {
		all: [],
		find: [],
		get: [],
		create: [],
		update: [],
		patch: [],
		remove: []
	},
	after: {
		all: [],
		find: [],
		get: [],
		create: [],
		update: [],
		patch: [],
		remove: []
	},
	error: {
		all: [],
		find: [],
		get: [],
		create: [],
		update: [],
		patch: [],
		remove: []
	}
})
export default servicePlugin
